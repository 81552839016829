export const cliendCodeToUrl = (clientCode: string) => {
  const currentUrl = window.location.href;
  let newUrl;

  if (currentUrl.includes('code')) {
    return null;
  }

  if (currentUrl.includes('?')) {
    newUrl = `${currentUrl}&code=${clientCode}`;
  } else {
    newUrl = `${currentUrl}?code=${clientCode}`;
  }

  window.history.pushState({ path: newUrl }, '', newUrl);
};
